:root {
}

@font-face {
    font-family: MilkyNiceClean;
    src: url("../font/milky-nice-clean.otf") format("opentype");
}

html {
	background-color: black !important;
	height: 100vh;

	body {
		background-color: black !important;
		height: 100vh;
	}
}
